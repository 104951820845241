@mixin backlight() {
  &::before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    z-index: -1;
    height: 100%;
    --webkit-filter: blur(60px);
    background: linear-gradient(to bottom, #a4be7bb7, #a4be7be3);
  }
}
