.cardProduct {
    cursor: pointer;
    background: rgb(231, 231, 231);
    border-radius: 20px;
    margin: 2rem 4rem;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 1.5rem 1.5rem;
    transition: all 0.3s ease;
}

.cardProduct:hover {
    transform: scale(1.1);
}

.cardProduct_image {
    width: 200px;
    height: 200px;
}

.cardProduct_image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.cardProduct_info {
    margin-top: 1rem;
}

.cardProduct_info h2 {
    margin-bottom: 10px;
    font-size: 1.5rem;
    color: black;
}

.cardProduct_info h3 {
    color: #272727;
    font-size: 1.2rem;
}
