@import "../abstracts/mediaquery";

main.gallery {
  padding: 2rem;
  @include respond(phone) {
    padding: .5rem;
    text-align: center;
  }
}

.gallery>h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: #333333;
  font-weight: 200;
  @include respond(phone) {
    font-size: 1.8rem;
    font-weight: 200;
  }
}

.gallery>ul>li {
  list-style: none;
}

.gallery>.gallery__list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
  grid-template-rows: repeat(14, 400px);
  
  grid-gap: 1rem;
  grid-auto-flow: dense;
  padding-left: 0;

  @include respond(phone) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-template-rows: repeat(1, 250px);
  }
  @include respond(smallphone) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-template-rows: repeat(1, 250px);
  }
}

.gallery>.gallery__list>li>figure {
  height: 100%;
}

.gallery>.gallery__list>li>figure>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  transition: all .5s ease;



  &:hover{
    transform: scale(1.1);
    
  }

}