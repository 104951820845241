.container-carousel2 {
  z-index: 0;
}

.carousel-indicators {
  visibility: hidden !important;
}
.d-block1 {
  height: 87vh;
  width: 100%;
  margin: auto;
  background: url("https://raw.githubusercontent.com/RealCodeMate/SoydanTarim/main/soydantarim/src/assets/galleryImg/photo15.jpeg") center center/cover
    no-repeat;
}
.d-block2 {
  height: 87vh;
  width: 100%;
  margin: auto;
  background: url("https://raw.githubusercontent.com/RealCodeMate/SoydanTarim/main/soydantarim/src/assets/galleryImg/photo40.jpeg") center center/cover
    no-repeat;
}
.d-block3 {
  height: 87vh;
  width: 100%;
  margin: auto;
  background: url("https://raw.githubusercontent.com/RealCodeMate/SoydanTarim/main/soydantarim/src/assets/galleryImg/photo5.jpeg") center center/cover
    no-repeat;
}
.d-block4 {
  height: 87vh;
  width: 100%;
  margin: auto;
  background: url("https://raw.githubusercontent.com/RealCodeMate/SoydanTarim/main/soydantarim/src/assets/galleryImg/photo25.jpeg") center center/cover
    no-repeat;
}

.carousel-control-prev,
.carousel-control-next {
  visibility: hidden !important;
}
