@import "../abstracts//variables";

.navbar {
  max-width: 95vw;
  margin: auto;
  background-color: #fff;
  position: relative;
  z-index: 1;

  &-nav {
    .dropdown-item.active {
      background-color: inherit;
    }

    .dropdown a {
      color: $color-primary;
      font-weight: 500;
      font-size: 1.3rem;
      transition: all 0.4s;

      &:hover {
        color: $color-third;
      }
    }

    a {
      h5 {
        margin-bottom: 0 !important;
        font-size: 1.4rem;
        color: $color-primary;
        transition: all 0.4s;

        &:hover {
          color: $color-third;
        }
      }
    }
  }

  .navbar-brand {
    img {
      height: 40px;
      width: 100px;
    }
  }

  .justify-content-start {
    justify-content: flex-start !important;
  }

  .justify-content-end {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1.2rem;

    h5 {
      margin-bottom: 0 !important;
      font-size: 1.4rem;
      color: $color-primary;
      transition: all 0.4s;

      &:hover {
        color: $color-third;
      }
    }
  }
}
