@import "../abstracts/variables";
@import "../abstracts/mediaquery";

.containerFooter {
  max-width: 60vw;
  margin: auto;
  border-bottom: 1px solid #f8f8f870;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

ul {
  list-style: none;
  padding-left: 0;
}

.footer {
  background: linear-gradient(to right,
      $color-secondary-light 0%,
      $color-primary 90%);
  padding: 20px 0;
  padding-top: 70px;
  position: relative;
  z-index: 1;

  &__copyright{
    font-weight: 900;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 34%;
    background: url("../../assets/backgrounds/wheat.png") top -40px center/cover no-repeat;
    opacity: 0.2;
    z-index: -1;

    @include respond(tab-port) {
      height: 100%;
      width: 100%;
    }

    @include respond(phone) {
      height: 100%;
      width: 100%;
    }
  }

  p {
    text-align: center;
    margin-top: 1rem;
    color: rgba(255, 255, 255, 0.733);
  }
}

.footer-col {
  width: 25%;

  @include respond(phone) {
    width: 50%;
    padding: 0 25px;
  }

  padding: 0 15px;
}

.footer-col h4 {
  font-size: 1.1rem;
  color: #ffffff;
  text-transform: capitalize;
  margin-bottom: 2rem;
  font-weight: 700;
  position: relative;
}

.footer-col h4::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
  background-color: $color-third;
  height: 2px;
  box-sizing: border-box;
  width: 50px;
}

.footer-col ul li:not(:last-child) {
  margin-bottom: 10px;
}

a[href^="tel:"] {
  text-decoration: none;
}

.footer-col ul li a h5 {
  font-size: 16px;
  text-transform: capitalize;
  color: #ffffff !important;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
  cursor: pointer;
}

.footer-col ul li a h5:hover {
  color: #ffffff;
  padding-left: 8px;
}

.footer-col .social-links svg {
  display: inline-block;
  cursor: pointer;
  height: 30px;
  width: 30px;
  margin-right: 12px;
  text-align: center;
  color: #ffffff;
  transition: all 0.5s ease;
}

.twitterLogo:hover {
  color: #1da1f2 !important;
}

.facebookLogo:hover {
  color: #3b5998 !important;
}

.instagramLogo:hover {
  color: #c32aa3 !important;
}

.scrollTop {
  display: flex;
  justify-content: flex-end;
}

.arrow {
  transform: rotate(180deg);
  cursor: pointer;
  margin-right: 1rem;
}

.arrow span {
  display: block;
  width: 1vw;
  height: 1vw;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
  transform: rotate(90deg);
  animation: animate 2s infinite;

  @include respond(phone) {
    width: 2.5vw;
    height: 2.5vw;
    border-bottom: 2px solid white;
    border-right: 2px solid white;
  }
}

.arrow span:nth-child(2) {
  animation-delay: -0.2s;
  border-bottom: 2px solid rgb(135, 15, 15);
  border-right: 2px solid rgb(135, 15, 15);

  @include respond(phone) {
    border-bottom: 2px solid rgb(135, 15, 15);
    border-right: 2px solid rgb(135, 15, 15);
  }
}

.arrow span:nth-child(3) {
  animation-delay: -0.4s;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}