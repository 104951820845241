@import '../abstracts/variables';
@import '../abstracts/mediaquery';

.about {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 3rem;

    &__top {
        display: flex;
        flex-direction: row;
        margin-bottom: 2rem;

        @include respond(tab-port) {
            flex-direction: column;
        }

        &--image {
            width: 50%;

            @include respond(tab-port) {
                width: 100%;
                margin-bottom: 3rem;
            }

            .img1 {
                width: 100%;
                height: 100%;
                margin-right: 1rem;
                border-radius: 20px;
                @include respond(phone) {
                    margin:0;
                }
            }
        }

        &--text {
            background-color: $color-secondary-light-2;
            width: 50%;
            padding-left: 1rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0 3rem;
            margin-left: 1rem;
            border-radius: 20px;
            @include respond(phone) {
                margin:0;
            }

            h2 {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                @include respond(phone) {
                    margin-top: 1rem;
                }
            }

            p {
                font-size: 1.2rem;
            }


            @include respond(tab-port) {
                width: 100%;
            }
        }
    }

    &__middle {
        margin-bottom: 3rem;

        @include respond(phone) {
            margin-bottom: 0;
        }

        &--banner {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            padding: 1rem;

            @include respond(tab-port) {
                width: 100%;
                flex-direction: column;
            }

            &>* {
                border-top: 5px solid $color-secondary-light;
                height: 100%;
                width: 32%;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 1rem;
                position: relative;
                overflow: hidden;
                z-index: 200;

                @include respond(tab-port) {
                    width: 100%;
                    margin-bottom: 1rem;
                }

                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 100%;
                    background: $color-secondary-light;
                    transition: 0.75s ease bottom;
                    z-index: -1;
                }

                &:hover {
                    color: $color-white;

                    &:before {
                        bottom: 0%;
                    }
                }
            }
        }
    }

    &__bottom {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;

        &--mission {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            margin-bottom: 2rem;

            @include respond(phone) {
                flex-direction: column-reverse;
            }

            .mission {
                background-color: $color-secondary-light-2;
                width: 80%;
                padding: 1rem;
                margin-right: 1rem;
                border-radius: 20px;

                p {
                    font-size: 1.2rem;
                }

                @include respond(phone) {
                    width: 100%;
                    margin-right: 0;
                }

            }

            .img__mission {
                width: 20%;
                height: 15rem;
                border-radius: 50%;
                margin: auto;

                @include respond(phone) {
                    width: 100%;
                    margin-bottom: 3rem;
                }

            }
        }

        &--vision {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            margin-bottom: 2rem;

            @include respond(phone) {
                flex-direction: column;
            }

            .vision {
                background-color: $color-secondary-light-2;
                width: 80%;
                padding: 1rem;
                margin-left: 1rem;
                border-radius: 20px;

                p {
                    font-size: 1.2rem;
                }


                @include respond(phone) {
                    width: 100%;
                    margin-left: 0;
                }

            }

            .img__vision {
                width: 20%;
                height: 15rem;
                border-radius: 50%;
                margin: auto;

                @include respond(phone) {
                    width: 100%;
                    margin-bottom: 3rem;
                }
            }
        }
    }
}