@import "../abstracts/variables";
@import "../abstracts/mixins";
@import "../abstracts/mediaquery";

.landingPage {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 0;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #000;
    opacity: 0.3;
    z-index: 1000;
  }

  &__container {
    position: absolute;
    color: #fff;
    max-width: 50%;
    margin: auto;
    z-index: 2000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    @include respond(tab-land) {
      max-width: 70%;
    }

    @include respond(tab-port) {
      max-width: 70%;
    }

    @include respond(phone) {
      max-width: 85%;
    }

    h2 {
      font-size: 6.2rem;
      // color: $color-secondary-dark;
      color: $color-white;

      @include respond(tab-land) {
        font-size: 5rem;
      }

      @include respond(tab-port) {
        font-size: 4rem;
      }

      span {
        // color: $color-secondary-light;
        color: $color-white;
      }
    }

    h5 {
      font-size: 2.6rem;
      padding: 2rem 6rem;
      color: $color-white;

      @include respond(tab-land) {
        font-size: 2rem;
      }

      @include respond(tab-port) {
        font-size: 2rem;
        padding: 2rem 3rem;
      }
    }

    .btn{
      font-size: 1.3rem;
      letter-spacing: 2px;
      padding: 10px 80px;
      color: #fff;
      background-color: $color-secondary-light;
      border-radius: 50px;
      cursor: pointer;
      text-transform: uppercase;

      @include respond(tab-port) {
        font-size: 1rem;
        padding: 10px 60px;
      }

      @include respond(phone) {
        font-size: 1rem;
        padding: 10px 40px;
      }
    }

    .btn {
      text-decoration: none;
      position: relative;
      overflow: hidden;
    }

    .btn:hover {
      box-shadow: 1px 1px 25px 10px #fff;
      color: #fff;
    }

    .btn:before {
      content: "";
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        120deg,
        transparent,
        $color-primary,
        transparent
      );
      transition: all 750ms;
    }

    .btn:hover:before {
      left: 100%;
    }
  }

  &__content {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 100;

    h1 {
      font-size: 100px;
      color: $color-white;
    }

    p {
      color: $color-white;
      margin-bottom: 3rem;
    }
  }

  &__button {
    color: $color-white;
    padding: 1rem 3rem;
    background-color: transparent;
    border: 5px solid $color-secondary-light;
    border-radius: 2rem;
    font-size: 1.5rem;
    text-decoration: none;

    &__wrapper {
      width: 100%;
    }
  }

  &__button:hover {
    background-color: $color-secondary-light;
    color: $color-black;
  }
}

.section2 {
  position: relative;
  @include backlight();
}

// ---------------------------

.landingImages {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;

  @include respond(phone) {
    flex-direction: column;
  }

  .img1 {
    width: 22rem;
    height: 20rem;
    border-radius: 10rem;
    margin-top: 4rem;
    cursor: pointer;

    @include respond(desktop) {
      width: 20rem;
      height: 15rem;
    }

    @include respond(tab-land) {
      width: 15rem;
      height: 10rem;
    }

    @include respond(tab-port) {
      width: 10rem;
      height: 7.5rem;
    }

    @include respond(phone) {
      width: 20rem;
      height: 15rem;
      margin-bottom: 1rem;
    }
  }
}

.landingInfo {
  display: flex;
  padding: 5rem;

  @include respond(tab-land) {
    padding: 4rem;
  }

  @include respond(tab-port) {
    padding: 3rem;
  }

  @include respond(phone) {
    padding: 2rem;
    flex-direction: column;
  }

  &__slogan {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    @include respond(phone) {
      width: 100%;
    }

    h3 {
      font-size: 3rem;
      margin-right: 0.5rem;
      color: #fff;

      @include respond(tab-land) {
        font-size: 2.5rem;
      }

      @include respond(tab-port) {
        font-size: 2rem;
      }

      @include respond(phone) {
        font-size: 2rem;
        margin-bottom: 1rem;
      }
    }
  }

  &__desc {
    width: 50%;
    padding: 4rem;
    font-size: 1.1rem;
    font-weight: bold;
    color: $color-primary;
    background-color: rgba(255, 255, 255, 0.349);
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @include respond(phone) {
      width: 100%;
    }

    @include respond(tab-land) {
      font-size: 1rem;
    }

    @include respond(tab-port) {
      font-size: 1rem;
    }

    @include respond(phone) {
      font-size: 1rem;
      padding: 2rem;
      text-align: left;
    }
  }
}

.landingChoose {
  display: flex;
  max-width: 95vw;
  margin: 3rem auto;
  padding: 3rem;
  background-color: #964b00;
  color: #fff;
  background: url("https://raw.githubusercontent.com/RealCodeMate/SoydanTarim/main/soydantarim/src/assets/backgrounds/landingImg.jpg") center center/cover
    no-repeat;
  color: #fff;
  position: relative;

  @include respond(tab-port) {
    flex-direction: column-reverse;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #000;
    opacity: 0.3;
  }

  h3 {
    font-size: 1.3rem;
    letter-spacing: 2px;
    padding: 10px 80px;
    color: #fff;
    background-color: $color-secondary-light;
    border-radius: 50px;
    cursor: pointer;
    text-transform: uppercase;
    z-index: 1;

    @include respond(tab-port) {
      font-size: 1rem;
      padding: 10px 60px;
    }

    @include respond(phone) {
      font-size: 1rem;
      padding: 10px 40px;
    }
  }

  .btn {
    text-decoration: none;
    position: relative;
    overflow: hidden;
  }

  .btn:hover {
    box-shadow: 1px 1px 25px 10px $color-primary;
  }

  .btn:before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      120deg,
      transparent,
      $color-primary,
      transparent
    );
    transition: all 750ms;
  }

  .btn:hover:before {
    left: 100%;
  }

  &__button {
    color: $color-black;
    padding: 1rem 3rem;
    background-color: $color-white;
    border: 5px solid black;
    border-radius: 2rem;
    font-size: 1.5rem;
    text-decoration: none;
    transition: 0.4s ease-in;
  }

  &__button:hover {
    background-color: $color-black;
    color: $color-white;
  }

  &__texts {
    width: 50%;
    padding: 2rem;
    padding-left: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2.5rem;
    z-index: 1;

    @include respond(tab-port) {
      width: 100%;
    }
  }

  .services {
    display: grid;
    grid-template-columns: auto auto;
    margin-top: 2rem;
    @include respond(phone) {
      display: inline;
    }

    &__heading {
      display: flex;
      flex-direction: row;
      margin-bottom: 2rem;
    }

    &__texts {
      margin: 0 1rem;

      h4 {
        @include respond(tab-land) {
          font-size: 1.3rem;
        }
        @include respond(phone) {
          font-size: 1rem;
        }
      }

      p {
        @include respond(phone) {
          font-size: 1rem;
        }
      }
    }
  }

  &__image {
    width: 50%;
    display: flex;
    border-radius: 10rem;
    justify-content: center;
    align-items: center;
    z-index: 1;

    @include respond(tab-port) {
      width: 100%;
      margin-top: 1rem;
    }

    .img1 {
      width: 60%;
      height: 50%;

      @include respond(tab-port) {
        width: 60%;
        height: 15rem;
      }

      @include respond(phone) {
        width: 70%;
        height: 15rem;
      }
    }
  }
}

.homeProducts {
  &__header {
    text-align: center;
    color: #fff;
    margin-top: 4rem;
    font-size: 3rem;

    @include respond(tab-port) {
      font-size: 3rem;
    }

    @include respond(phone) {
      font-size: 2.5rem;
    }
  }

  &__elements {
    text-align: center;
    margin: 1rem auto;
    padding: 3rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    gap: 50px;
    position: relative;
    z-index: 100;

    @include respond(tab-port) {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }

    @include respond(phone) {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }

    a {
      text-decoration: none;
    }

    .flex_products {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    h3 {
      font-size: 2rem;
      padding-top: 2rem;
      position: relative;
      z-index: 100;
      color: #fff;
      margin: 0;
    }

    h5 {
      color: #fff;
      display: inline-block;
      padding: 10px 20px;
      background-color: $color-primary;
      border: 1.5px solid $color-primary;
      border-radius: 20px;
      transition: all 1s;
      margin-bottom: 3rem;
    }
  }
}

.card-hover {
  $root: &;
  width: 100%;
  height: 400px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 32px -10px rgba(0, 0, 0, 0.08);

  &:hover {
    #{$root} {
      &__content {
        background-color: #dee8c2;
        bottom: 100%;
        transform: translateY(100%);
        padding: 50px 60px;
        transition: all 0.35s cubic-bezier(0.1, 0.72, 0.4, 0.97);
      }

      &__link {
        opacity: 1;
        transform: translate(-50%, 0);
        transition: all 0.3s 0.35s cubic-bezier(0.1, 0.72, 0.4, 0.97);
      }
    }

    img {
      transform: scale(1.1);
      transition: 0.35s 0.1s transform cubic-bezier(0.1, 0.72, 0.4, 0.97);
    }
  }

  &__content {
    width: 100%;
    text-align: center;
    background-color: #86b971;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(0);
    transition: all 0.35s 0.35s cubic-bezier(0.1, 0.72, 0.4, 0.97);
    will-change: bottom, background-color, transform, padding;
    z-index: 1;

    &::before,
    &::after {
      content: "";
      width: 100%;
      height: 120px;
      background-color: inherit;
      position: absolute;
      left: 0;
      z-index: -1;
    }

    &::before {
      top: -80px;
      clip-path: ellipse(60% 80px at bottom center);
    }

    &::after {
      bottom: -80px;
      clip-path: ellipse(60% 80px at top center);
    }
  }

  &__title {
    font-size: 1.5rem;
    margin-bottom: 1em;

    span {
      color: #2d7f0b;
    }
  }

  &__text {
    margin-top: 1rem;
    font-size: 0.95rem;
  }

  &__link {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translate(-50%, 10%);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-decoration: none;
    color: #2d7f0b;
    opacity: 0;
    padding: 10px;
    transition: all 0.35s;

    &:hover {
      color: #fff;

      svg {
        transform: translateX(4px);
      }
    }

    svg {
      width: 18px;
      margin-left: 4px;
      transition: transform 0.3s;
    }
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: -1;
    transform: scale(1.2);
    transition: 0.35s 0.35s transform cubic-bezier(0.1, 0.72, 0.4, 0.97);
  }
}

.comments {
  max-width: 95vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  color: #000;

  &__header {
    text-align: center;
    color: #fff;
    font-size: 3rem;

    @include respond(phone) {
      font-size: 2.5rem;
    }
  }

  &__slider {
    height: 90%;
    margin-bottom: 4rem;
    justify-content: center;

    .img1 {
      margin-top: 1rem;
      width: 70%;
      height: 80%;
    }
  }
}
