@import '../abstracts/variables';
@import '../abstracts/mediaquery';


.contact {
    width: 100%;
    height: 88vh;
    display: flex;
    flex-direction: row;

    @include respond(tab-land) {
        flex-direction: column;
    }

    &__left {
        height: 100%;
        width: 50%;

        @include respond(tab-land) {
            height: 50%;
            width: 100%;
            margin-bottom: 5rem;
        }

        &__form {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            form {
                width: 80%;
                padding: 1rem;

                @include respond(tab-land) {
                    height: 90%;
                }
            }

            .feedback-input {
                color: $color-black;
                font-family: Helvetica, Arial, sans-serif;
                font-weight: 500;
                font-size: 18px;
                border-radius: 5px;
                line-height: 22px;
                background-color: transparent;
                border: 1px solid #222;
                transition: all 0.3s;
                padding: 13px;
                margin-bottom: 15px;
                width: 100%;
                box-sizing: border-box;
                outline: 0;
            }

            .feedback-input-2 {
                color: black;
                font-family: Helvetica, Arial, sans-serif;
                font-weight: 500;
                font-size: 18px;
                border-radius: 5px;
                line-height: 22px;
                background-color: transparent;
                border: 1px solid #222;
                transition: all 0.3s;
                padding: 13px;
                margin-bottom: 15px;
                width: 100%;
                box-sizing: border-box;
                outline: 0;
                height: 20rem;

                @include respond(tab-land) {
                    height: 10rem;
                }

            }

            .feedback-input:focus {
                border: 2px solid $color-secondary-dark;
            }

            textarea {
                height: 150px;
                line-height: 150%;
                resize: vertical;
            }

            [type="submit"] {
                width: 30%;
                background: #A4BE7B;
                border-radius: 5px;
                border: 0;
                cursor: pointer;
                color: $color-white;
                font-size: 1.4rem;
                padding-top: 10px;
                padding-bottom: 10px;
                transition: all 0.3s;
                margin-top: -4px;
                font-weight: 700;
            }

            [type="submit"]:hover {
                opacity: 0.8;
            }
        }
    }

    &__right {
        height: 100%;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        @include respond(tab-land) {
            width: 100%;
            height: 50%;
        }

        &__map {
            width: 80%;
            height: 60%;

            @include respond(tab-land) {
                height: 80%;
            }
        }

    }
}