@import "../abstracts/variables";
@import "../abstracts/mixins";
@import "../abstracts/mediaquery";

.containerHeader {
  h2 {
    font-size: 4rem;
    margin-bottom: 3rem;
    text-align: center;
    color: #004109;

    @include respond(tab-land) {
      font-size: 3rem;
    }
    @include respond(tab-port) {
      font-size: 2.5rem;
    }
    @include respond(phone) {
      font-size: 1.8rem;
      margin-bottom: 3rem;
    }

    span {
      color: #ffea20;
    }
  }
}

.containerCard {
  position: relative;
  width: 100%;
  min-height: 72vh;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 60px;
  z-index: 0;
  margin-bottom: 3rem;
  align-content: center;
}

.card {
  position: relative;
  width: 300px;
  height: 450px !important;
  padding: 0 !important;
  --color: #ffe9208c;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    inset: -10px 50px;
    border-top: 4px solid var(--color);
    border-bottom: 4px solid var(--color);
    transform: skewY(-15deg);
    transition: 0.5s ease-in-out;
    z-index: 0;
  }

  &::after {
    content: "";
    position: absolute;
    inset: 60px -10px;
    border-left: 4px solid var(--color);
    border-right: 4px solid var(--color);
    transform: skewX(15deg);
    transition: 0.5s ease-in-out;
    z-index: 0;
  }

  &:hover::before {
    inset: -10px 40px;
    transform: skewY(0deg);
  }

  &:hover::after {
    inset: 40px -10px;
    transform: skew(0deg);
  }

  .content {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background: #004109ad;
    gap: 20px;
    overflow: hidden;
    z-index: 10;

    &__text {
      h3 {
        color: #fff;
        font-size: 1.5rem;
        margin: 2rem auto;
        margin-bottom: 1.2rem;
        font-weight: 500;
        transition: 0.5s ease-in-out;

        &:hover {
          color: #ffea20;
        }
      }

      p {
        color: rgb(255, 255, 255);
        transition: 0.5s ease-in-out;

        &:hover {
          color: #ffea20;
        }
      }

      a {
        position: relative;
        display: inline-block;
        background: #ffe9208c;
        color: #fff;
        text-decoration: none;
        font-weight: 500;
        margin-bottom: 2rem;
        margin-top: 1rem;
        padding: 8px 15px;
        transition: all 0.4s ease;

        &:hover {
          background: #2e2e2e;
          color: var(--color);
        }
      }
    }
  }
}
