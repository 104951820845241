@mixin respond($breakpoint) {
  //300px
    @if $breakpoint == smallphone {
      @media (max-width: 18.75rem) {
        @content;
      }
    }
    //368px
    @if $breakpoint == miniphone {
      @media (max-width: 23rem) {
        @content;
      }
    }
  //480px
    @if $breakpoint == phone {
      @media (max-width: 30rem) {
        @content;
      }
    }
  //768px
    @if $breakpoint == tab-port {
      @media (max-width: 48rem) {
        @content;
      }
    }
   //1024px
    @if $breakpoint == tab-land {
      @media (max-width: 64rem) {
        @content;
      }
    }
    //1200px
    @if $breakpoint == desktop {
      @media (max-width: 75rem) {
        @content;
      }
    }
  }
  